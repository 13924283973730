<template>
  <div class="vip-container main-cnt">
    <div class="title">会员列表</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="VipApi.memberList" :columns="tableColumns" @edit="openDialog" @onGiftBtn="onGiftBtn">
        <template #operate>
          <el-button type="primary" round @click="openDialog">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增会员
          </el-button>
        </template>
      </common-table>
    </div>
    <w-dialog ref="dialogRef" class="vip-dialog" :title="ruleForm.m_id ? '编辑会员' : '新增会员'" width="50%" btnWidth="140px"
      top="20vh" :confirmText="ruleForm.m_id ? '确认编辑' : '确认新增'" @wConfirm="handleSure">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="会员姓名" prop="m_realname">
              <el-input v-model="ruleForm.m_realname" clearable placeholder="请输入会员姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="会员性别" prop="m_sex">
              <el-select v-model="ruleForm.m_sex" placeholder="请选择会员性别" clearable>
                <el-option label="未知" :value="0"></el-option>
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="m_idcard">
              <el-input v-model="ruleForm.m_idcard" placeholder="请输入身份证号" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="联系方式" prop="m_mobile">
              <el-input v-model="ruleForm.m_mobile" placeholder="请输入联系方式" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="会员来源" prop="m_platform">
              <el-select v-model="ruleForm.m_platform" placeholder="请选择会员来源" clearable
                :disabled="ruleForm.m_id ? true : false">
                <el-option v-for="el in PlatformOptions" :key="el.id" :label="el.name" :value="el.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属公司" prop="m_company">
              <el-input v-model="ruleForm.m_company" clearable placeholder="请输入所属公司"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="职位名称" prop="m_position">
              <el-input v-model="ruleForm.m_position" placeholder="请输入职位名称" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>

    <w-dialog ref="dialogGiftRef" title="优惠券明细" width="60%" btnWidth="140px" top="10vh" :hideFooter="true">
      <div style="text-align: end;">
        <el-button type="primary" round @click="onDistributeCoupons"
          v-if="authData.indexOf('m_N54b8Ueyu54yVD6HfaGKTxrZuaTn') != -1">发放优惠券</el-button>
      </div>
      <common-table ref="giftTable" tableHeight="calc(100vh - 360px)" :ischeck="false" :ispaging="true"
        :extraParame="{ m_id: currentRow.m_id }" :apiName="VipApi.getMemberGiftLists" :columns="giftTableColumns">
        <template #onRevokeBtn="{ row }">
          <el-button v-if="row.revoke_status == '2' && authData.indexOf('m_fJn4lmOpAEwiXEnViYn3JOGGcdLU') != -1"
            @click="onRevokeBtn(row)">撤销</el-button>
        </template>
      </common-table>
    </w-dialog>

    <!-- 发放优惠券 -->
    <w-dialog ref="couponDialogRef" title="发放优惠券" width="50%" btnWidth="140px" top="10vh" :confirmText="'确认保存'"
      @wConfirm="onConfirmSave">
      <el-form class="add-form" ref="couponFormRef" :model="formData" :rules="formRules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="景区" prop="s_id">
              <el-select v-model="formData.s_id" placeholder="请选择景区" @change="getCouponList" clearable>
                <el-option :label="item.s_name" :value="item.s_id" v-for="item in scenicOptions" :key="item.s_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="优惠券" prop="gid">
              <el-select v-model="formData.gid" placeholder="请选择优惠券" clearable>
                <el-option :label="item.g_name" :value="item.g_id" v-for="item in couponOptions" :key="item.g_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="数量" prop="num">
              <el-input type="number" v-model="formData.num" clearable placeholder="请输入数量"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="有效期" prop="periodValidity">
              <el-date-picker v-model="formData.periodValidity" type="daterange" range-separator="至"
                start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD" :clearable="false">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, nextTick, computed, watch, } from "vue";
  import { VipApi, BasicApi } from "@/plugins/api.js";
  import { ElMessage, ElMessageBox, } from "element-plus";
  import { checkStr } from "@/utils/common.js";
  import { useStore } from "vuex";

  const store = useStore();
  const authData = ref([]);  // 权限
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  /**
   *
   * 监听权限
   *
   **/
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  const PlatformOptions = ref([]);
  onMounted(() => {
    tableRef.value.tableLoad();
    BasicApi.getPlatformOptions().then((res) => {
      PlatformOptions.value = res.Data;
      filters.value[1].options = res.Data;
    });
  });

  const filters = ref([
    {
      filterType: "search",
      name: "keyword",
      value: "",
      placeholder: "搜索会员姓名、手机号",
    },
    {
      filterType: "select",
      name: "m_platform",
      value: "",
      placeholder: "请选择会员来源",
      options: [],
      val: "id",
      lab: "name",
    },
  ]);
  /** 表格对象 */
  const tableRef = ref(null);

  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "m_id",
      label: "会员ID",
      color: "--text-third-color",
    },
    {
      prop: "m_realname",
      label: "会员姓名",
    },
    {
      prop: "m_sex",
      label: "会员性别",
      type: "option",
      metaData: ["未知", "男", "女"],
      colorData: [
        "var(--text-third-color)",
        "var(--text-third-color)",
        "var(--text-third-color)",
      ],
    },
    {
      prop: "m_mobile",
      label: "联系方式",
      minWidth: 120,
    },
    {
      prop: "m_platform",
      label: "会员来源",
      type: "option",
      metaData: ["", "微信小程序", "系统后台"],
      colorData: ["", "var(--text-third-color)", "var(--text-third-color)"],
    },
    {
      type: "block",
      prop: "member_gift_count",
      label: "优惠券",
      active: "onGiftBtn",
      minWidth: 80,
      token: "m_SsXS9uLsqEtYndGsJzQNbPnDKOxy",
    },
    {
      prop: "u_name",
      label: "录入人员",
    },
    {
      prop: "m_ctime",
      label: "录入时间",
      minWidth: 130,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "编辑",
          action: "edit",
          token: "m_mx9wiI3QYNfu0g7T8h2tUJCZaAKO",
        },
      ],
    },
  ]);

  const dialogRef = ref(null);
  const formRef = ref(null);
  const ruleForm = ref({
    m_realname: "",
    m_sex: "",
    m_idcard: "",
    m_mobile: "",
    m_platform: "",
    m_company: "",
    m_position: "",
  });
  const rules = reactive({
    m_realname: [
      {
        required: true,
        message: "请输入会员姓名",
        trigger: "blur",
      },
    ],
    m_mobile: [
      {
        required: true,
        message: "请输入联系方式",
        trigger: "blur",
      },
    ],
    m_platform: [
      { required: true, message: '请选择会员来源', trigger: 'change' }
    ],
    // m_company: [
    //   {
    //     required: true,
    //     message: "请输入所属公司",
    //     trigger: "blur",
    //   },
    // ],
    // m_position: [
    //   {
    //     required: true,
    //     message: "请输入公司职位",
    //     trigger: "blur",
    //   },
    // ],
  });
  const openDialog = (row) => {
    if (row.m_id) {
      //编辑
      const data = JSON.parse(JSON.stringify(row));
      ruleForm.value = data;
    } else {
      // 新增
      ruleForm.value = {
        m_realname: "",
        m_sex: "",
        m_idcard: "",
        m_mobile: "",
        m_platform: "",
        m_company: "",
        m_position: "",
      };
    }
    dialogRef.value.show();
  };
  /** 新增/编辑 确认提交 */
  const handleSure = () => {
    formRef.value.validate((valid) => {
      if (valid) {
        dialogRef.value.isLoading = true;
        const data = JSON.parse(JSON.stringify(ruleForm.value));
        if (!checkStr(data.m_realname, "chinese")) {
          ElMessage.warning("请输入正确的会员姓名！");
          dialogRef.value.isLoading = false;
          return false;
        }
        if (!checkStr(data.m_mobile, "phone")) {
          ElMessage.warning("联系方式格式错误！");
          dialogRef.value.isLoading = false;
          return false;
        }
        if (data.m_idcard) {
          if (!checkStr(data.m_idcard, "card")) {
            ElMessage.warning("身份证格式错误！");
            dialogRef.value.isLoading = false;
            return false;
          }
        }

        let parmas = {
          m_realname: data.m_realname,
          m_sex: data.m_sex,
          m_mobile: data.m_mobile,
          m_idcard: data.m_idcard,
          m_position: data.m_position,
          m_company: data.m_company,
          m_platform: data.m_platform,
        };
        let url = "";
        if (data.m_id) {
          // 编辑
          url = "memberUpdate";
          parmas["m_id"] = data.m_id;
        } else {
          // 新增
          url = "memberAdd";
        }
        VipApi[url](parmas).then((res) => {
          dialogRef.value.isLoading = false;
          const text = data.m_id ? "修改" : "新增";
          if (res.Code === 200) {
            ElMessage.success(`会员${text}成功！`);
            dialogRef.value.close();
            tableRef.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : `会员${text}失败！`;
            ElMessage.error(msg);
          }
        });
      }
    });
  };
  /** 当前操作行 */
  const currentRow = ref(null);
  const dialogGiftRef = ref(null);
  const giftTable = ref(null);
  const giftTableColumns = ref([
    {
      prop: "mg_name",
      label: "名称",
    },
    {
      prop: "s_name",
      label: "所属园区",
    },
    {
      prop: "gt_name",
      label: "类型",
    },
    {
      prop: "mg_status_text",
      label: "状态",
    },
    {
      prop: "mg_check_time",
      label: "使用时间",
      minWidth: 100
    },
    {
      prop: "mg_stime",
      label: "开始日期",
    },
    {
      prop: "mg_etime",
      label: "结束日期",
    },
    {
      prop: "mg_type_text",
      label: "来源",
    },
    {
      prop: "create_user",
      label: "操作人员",
    },
    {
      prop: "g_desc",
      label: "使用说明",
      showTooltip: true,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "撤销",
          action: "onRevokeBtn",
          type: "customRender",
        },
      ],
    },
  ]);
  /**
   * 
   * 查看会员优惠券
   * 
   * */
  const onGiftBtn = (row) => {
    currentRow.value = row;
    dialogGiftRef.value.show();
    nextTick(() => {
      giftTable.value.tableLoad();
    });
  }
  const couponDialogRef = ref(null);  // 发放优惠券对象
  const couponFormRef = ref(null);  // 表单对象
  const scenicOptions = ref([]);  // 景区选项
  const couponOptions = ref([]);  // 优惠券选项
  const formData = ref({
    s_id: '',  // 景区
    gid: '',  // 优惠券
    num: '',  // 数量
    periodValidity: [],  // 有效期
  });
  const formRules = reactive({
    s_id: [
      { required: true, message: "请选择景区", trigger: "change", },
    ],
    gid: [
      { required: true, message: "请选择优惠券", trigger: "change", },
    ],
    num: [
      { required: true, message: "请输入数量", trigger: "blur", },
    ],
    periodValidity: [
      { required: true, message: "请选择有效期", trigger: "change", },
    ],
  });
  /**
   * 
   * 发放优惠券
   * 
   * */
  const onDistributeCoupons = () => {
    getAllScenicData();
    couponDialogRef.value.show();
    formData.value = {
      s_id: '',  // 景区
      gid: '',  // 优惠券
      num: '',  // 数量
      periodValidity: [],  // 有效期
    };
    nextTick(() => {
      couponFormRef.value.resetFields();
    });
  }
  /**
   * 
   * 获取景区选项
   * 
   * */
  const getAllScenicData = () => {
    BasicApi.getAllScenicData({ auth: '1' }).then((res) => {
      if (res.Code === 200) {
        scenicOptions.value = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 选择景区获取优惠券选项
   * 
   * */
  const getCouponList = () => {
    formData.value.gid = '';  // 清空优惠券选择id
    if (!formData.value.s_id) {
      couponOptions.value = [];  // 清空优惠券选项
      return false;
    }
    VipApi.getCouponList({ send_type: '1', s_id: formData.value.s_id }).then((res) => {
      if (res.Code === 200) {
        couponOptions.value = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 确定发放按钮
   * 
   * */
  const onConfirmSave = () => {
    if (couponFormRef.value) {
      couponFormRef.value.validate((valid) => {
        if (valid) {
          let params = {
            m_id: currentRow.value.m_id,
            gift_list: [{
              gid: formData.value.gid,
              num: formData.value.num,
              start_time: formData.value.periodValidity[0],
              end_time: formData.value.periodValidity[1],
            }],
          };
          couponDialogRef.value.isLoading = true;
          VipApi.generateMemberGift(params).then((res) => {
            if (res.Code === 200) {
              giftTable.value.tableLoad();
              couponDialogRef.value.close();
              tableRef.value.tableLoad();
              ElMessage.success('优惠券发放成功！');
            } else {
              ElMessage.error(res.Message);
            }
            couponDialogRef.value.isLoading = false;
          });
        }
      });
    }
  }
  /**
   * 
   * 撤销按钮
   * 
   * */
  const onRevokeBtn = (row) => {
    ElMessageBox.confirm(
      `是否确认撤销该优惠券！`,
      '撤销提示',
      { confirmButtonText: '确认', cancelButtonText: '取消', }
    )
      .then(() => {
        VipApi.revokeMemberGift({ mg_id: row.mg_id }).then((res) => {
          if (res.Code === 200) {
            giftTable.value.tableLoad();
            tableRef.value.tableLoad();
            ElMessage.success('撤销成功！');
          } else {
            ElMessage.error(res.Message);
          }
        });
      })
      .catch(() => { })
  }
</script>
<style lang="scss">
  .vip-container {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;
    }

    .vip-dialog {
      .el-dialog {
        min-width: 600px;

        .el-dialog__body {
          padding: 30px;

          .el-divider--horizontal {
            margin: 10px 0;
          }
        }
      }
    }
  }
</style>